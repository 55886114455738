
import { mdiBikeFast, mdiBicycle, mdiHandHeartOutline } from "@mdi/js";
import { mapGetters } from "vuex";

import background from "~/assets/images/background.jpg";
import grandview from "~/assets/images/grandview.svg";
import lhf from "~/assets/images/lhf.svg";
import lhfLight from "~/assets/images/lhf_light.png";
import oakRidges from "~/assets/images/oakridges.png";
import ourCancer from "~/assets/images/our_cancer.png";
import pphf from "~/assets/images/pphf.png";
import scannerBg from "~/assets/images/scanner.jpg";

export default {
  layout: "index",

  data() {
    return {
      background,
      donateLink:
        "https://register.portperrypedals.ca/fundraising-organizations",
      grandview,
      lhf,
      lhfLight,
      mdiBicycle,
      mdiBikeFast,
      mdiHandHeartOutline,
      oakRidges,
      ourCancer,
      pphf,
      scannerBg,
    };
  },

  head() {
    return {
      title: "September 8, 2024",
    };
  },

  computed: {
    ...mapGetters("navigation", ["canadaHelpsLink"]),
  },
};
